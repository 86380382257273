import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReactiveFooter","FooterTabTitle"] */ "/vercel/path0/src/app/@footer/components.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainMenu"] */ "/vercel/path0/src/app/menu/components.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Card"] */ "/vercel/path0/src/components/display/card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/src/components/input/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SheetTrigger","SheetOverlay","SheetContent"] */ "/vercel/path0/src/components/layout/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HasClaimedProfileName"] */ "/vercel/path0/src/components/logic/has-claimed-profile-name.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/navigation/navigation-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileName"] */ "/vercel/path0/src/components/strings/profile-name.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuSheet"] */ "/vercel/path0/src/modules/main-menu/menu-sheet.tsx");
